import { useEffect, useState } from 'react';
import socketClient  from "socket.io-client";
import { useParams } from 'react-router-dom';
import { appLinkExternalUCLA, userImport } from '../utils/constants';
import { Button, Typography } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { theme } from '../utils/theme';
import axios from 'axios';

// const MAX_WIDTH = "100vw"
const MAX_WIDTH = Math.min(window.innerWidth, 500)
const FRIEND_SIZE = 82;
const FRIENDS_VISIBLE = 4;


export const MemberProfileScreen = () => {
  const { id } = useParams();
  const [ itemObj, setItemObj ] = useState()
  const [ friendsObj, setFriendsObj ] = useState()

  const API = "http://slate-env-1.eba-jfvabf2m.us-west-1.elasticbeanstalk.com"
  // const API = "http://127.0.0.1:80";


  const getUser = async (_id) => {
    let result = await axios.get(`${API}/user`, { params: { _id: "mk5J2QgBj2gCvcVOhJMMQbRBl9E2" }})
    let user = result.data

    setItemObj(user)
    let memberRelationshipsArray = user?.friends ? Object.values(user.friends) : []
    let memberFriends = memberRelationshipsArray.filter((k) => k.type == "friend")
    setFriendsObj([...memberFriends])
    console.log(user);
  }

  useEffect(() => {
    getUser(id)
  }, [])



  const RenderIcon = ({ field }) => {
    const style = {color: theme.colors.text2, fontSize: '20px'}
    
    if ( field == "memberDatabase" ) return (
      <SchoolOutlinedIcon style={style}/>
    )
    else if ( field == "gradYear" ) return (
      <EventOutlinedIcon style={style}/>
    )
    else if ( field == "major" ) return (
      <WorkOutlineOutlinedIcon style={style}/>
    )
    else if ( field == "hometown" ) return (
      <HomeOutlinedIcon style={style}/>
    )
    else if ( field == "relationshipStatus" ) return (
      <LinkOutlinedIcon style={style}/>
    )

  }

  const RenderLineItem = ({ field, value, icon }) => {
    return (
      <div style={{ borderRadius: 15, overflow: "hidden", width: "100%", alignSelf: "center", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 12}}
      >
        <RenderIcon field={field} />
        <div style={{ color: theme.colors.text2, fontSize: '20px', marginLeft: 12 }}>
          {value}
        </div>
      </div>
    );
  }

  const RenderFriend = ({ item }) => {
    return (
      <div style={{flexDirection: "column", alignItems: "center", marginRight: -15, justifyContent: "center"}}
      >
        <img style={{ height: FRIEND_SIZE, aspectRatio: 1, borderRadius: 180 }}
          src={item.profilePicture} 
        />
        <Typography style={{color: theme.colors.text2, marginTop: 5, fontSize: 12, textAlign: "center"}}
          >{item.firstName} {item?.lastName?.slice(0,1)}
        </Typography>

      </div>
    )
  }

  const RenderChip = ({ array, field, color }) => {
    if ( itemObj ) return array.map((item) => {
      let message = field ? `${item[field]}` : item

      return (
        <div style={{flexDirection: "column", alignItems: "center", justifyContent: "center", borderColor: theme.colors.backgroundTertiary, borderWidth: 1, paddingTop: 8, paddingBottom: 8, marginRight: 8, borderRadius: 15, marginBottom: 10, backgroundColor: color || `${theme.colors.backgroundSecondary}`, paddingLeft: 15, paddingRight: 15,  }}
          key={message}
        >
          <div style={{color: theme.colors.text2, fontSize: 15, textAlign: "center", }}
            >{message}
          </div>
        </div>
      )
    })
    else return null
  }

  const RenderPicture = ({ i }) => {
    if ( itemObj?.[`profilePicture${i}`] ) {
      return (
        <div style={{alignSelf: "center", marginBottom: 20,   }}>
            <img style={{ width: MAX_WIDTH, aspectRatio: 4/5, verticalAlign: "middle", borderRadius: "5%" }}
              src={itemObj?.[`profilePicture${i}`]} 
            />
        </div>
      )
      
    }
    else return null;
  }

  const RenderPrompt = ({ i }) => {
    if ( itemObj?.prompts?.[i] ) {
      let item = itemObj.prompts[i]
      return (
        <div style={{marginTop: 40, marginBottom: 40, }}>
          <div style={{alignSelf: "center", paddingLeft: 20, paddingRight: 20,}}>
            <Typography style={{fontSize: 16, color: theme.colors.text3, fontStyle: "italic", textAlign: "center" }}
              >{item.prompt}
            </Typography>
            <Typography style={{fontSize: 22, color: theme.colors.text2, fontWeight: '500', letterSpacing: 1, marginTop: 10, textAlign: "center", }}
              >{item.message}
            </Typography>
          </div>
        </div>        
      )
    }
    else return null
  }

  const handleClick= () => {
    window.location.href = appLinkExternalUCLA;
  }

  if ( itemObj) return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'black', height: '100vh', overflowY: 'scroll' }}>

      <div style={{maxWidth: MAX_WIDTH,}}>
        
        {/* HEADER */}
        <img 
          style={{ width: "100%", height: 'auto', objectFit: 'cover', marginBottom: 0, borderBottomLeftRadius: "5%", borderBottomRightRadius: "5%"  }}
          src={itemObj?.profilePicture0}  
        />
        {/* position: 'sticky', top: 0, */}
        {/* TITLE */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px', backgroundColor: theme.colors.backgroundApp, }}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', color: 'white', marginBottom: '7px', }}
            >{itemObj?.firstName} {itemObj?.lastName}
          </div>
          <div style={{ fontSize: '20px', color: 'white' }}
            >@{itemObj?.alias}
          </div>
        </div>

        {/* LINE ITEMS */}
        <RenderLineItem value={itemObj?.memberDatabase} field={"memberDatabase"} />
        <RenderLineItem value={itemObj?.gradYear} field={"gradYear"} />
        <RenderLineItem value={itemObj?.major} field={"major"} />
        {/* <RenderLineItem value={itemObj?.age} /> */}
        <RenderLineItem value={itemObj?.hometown} field={"hometown"} />
        <RenderLineItem value={itemObj?.relationshipStatus} field={"relationshipStatus"} />

        <div style={{marginHorizontal: 15, }}>

          { friendsObj && friendsObj.length > 0 &&
              <>
                <Typography style={{fontSize: 16, color: theme.colors.text, fontWeight: "600", marginTop: 30 }}
                  >{`Friends (${friendsObj.length})`}
                </Typography>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, paddingLeft: 15 }}>
                  { friendsObj.slice(0, FRIENDS_VISIBLE).map((item, index) => (
                      <RenderFriend key={item._id + index} item={item} />
                    ))
                  }
                </div>
              </>
            }

            { itemObj?.groups && itemObj?.groups.length > 0 &&
              <>
                <Typography style={{fontSize: 16, color: theme.colors.text, fontWeight: "600", marginTop: 20 }}
                  >{"Groups"}
                </Typography>

                <div style={{display: "inline-flex", flexWrap: "wrap", marginTop: 8, paddingLeft: 10, }}>
                  <RenderChip 
                    array={itemObj?.groups ? itemObj.groups : ["Nothin"]} 
                    color={`#9a00ff50`}
                    field={"name"}
                  />
                </div>
              </>
            }

            {itemObj?.openTo && itemObj?.openTo.length > 0 &&
              <>
                <Typography style={{fontSize: 16, color: theme.colors.text, fontWeight: "600", marginTop: 20 }}
                  >{"Open to"}
                </Typography>

                <div style={{display: "inline-flex", flexWrap: "wrap", marginTop: 8, paddingLeft: 10, }}>
                  <RenderChip 
                    array={itemObj?.openTo ? itemObj.openTo.sort() : ["Nothin"]} 
                    color={`${theme.colors.secondary}50`}
                  />
                </div>
              </>
            }

            { itemObj?.interests && itemObj?.interests.length > 0 &&
              <>
                <Typography style={{fontSize: 16, color: theme.colors.text, fontWeight: "600", marginTop: 20 }}
                  >{"Interests"}
                </Typography>
                <div style={{display: "inline-flex", flexWrap: "wrap", marginTop: 8, paddingLeft: 10, }}>
                  <RenderChip 
                    array={itemObj?.interests ? itemObj.interests.sort() : []} 
                    color={`${theme.colors.primary}50`}
                  />
                </div>
              </>
            }

        </div>

        <div style={{marginTop: 30}}></div>
          <RenderPicture i={1}/>
          <RenderPrompt i={0}/>
          <RenderPicture i={2}/>
          <RenderPicture i={3}/>
          <RenderPrompt i={1}/>
          <RenderPicture i={4}/>
          <RenderPicture i={5}/>
          <RenderPrompt i={2}/>
          <RenderPicture i={6}/>

          <Button style={{position: 'fixed', bottom: 20, left: '55vw', backgroundColor: theme.colors.primary}}
            variant="contained"
            onClick={handleClick}
            >Get MAYA
          </Button>

      </div>    

      
    </div>
  )
  else return null

}


