
const colorPrimary = "#3D86F2"                    // 70b9ff (current), 329BFF, 3D86F2
const colorSecondary = "#D5007D"                  // D5007D (current), FF7598, 
const colorTertiary = "#A35CBD"                   // A35CBD (current)
const colorBackgroundApp = "#000000"              // 050f19 (current), 000000, 27292D, 1f2023, 050f19, 1E232D
const colorBackgroundSecondary = "#1e2730"        // 1e2730 (current), 1C1C1E, 27292D, 000000, 1f2023
const colorBackgroundTertiary = "#373f47"         // 373f47 (current), 2D3035, 2C2C2E, 202934 
const colorText = "#F5F8FA"                       // ffffff, F5F8FA
const colorText2 = "#E1E1E1"                      // cccccc, c6c6c6, 98989D, 8D8D93 (apple), E1E8ED, D4dbdb, 9EA5A4
const colorText3 = "grey"
const colorListSeparator = "#1C1C1E"              // same as background secondary
const colorShadow = "#000000"                     // "#2e2e2e" (PREVIOUS)
const tintShade = "dark"
const surfaceText = colorText


export const theme = {
colors: {
  primary: colorPrimary,
  secondary: colorSecondary,
  tertiary: colorTertiary,
  backgroundApp: colorBackgroundApp,
  backgroundSecondary: colorBackgroundSecondary,   
  backgroundTertiary: colorBackgroundTertiary,    
  text: colorText,
  text2: colorText2,
  text3: colorText3,
  listSeparator: colorListSeparator,               
  shadow: colorShadow,
  tintShade: tintShade,
  surfaceText: surfaceText,
}
}