export const appLinkExternalUCLA = "https://testflight.apple.com/join/1WgW9hlA"


export const userImport = {
  _id: "0UUbG2D28kctMrAPKdMPWgkU0eZ2",
  accountCompleted: 1,
  active: true,
  alias: "ryanM",
  birthday: "2000-05-30T04:00:00.000Z",
  channels: ["1", "2", "0UUbG2D28kctMrAPKdMPWgkU0eZ22Ru3zbq2izg6xhLWNNaERUBpmvA2", "0UUbG2D28kctMrAPKdMPWgkU0eZ2:2Ru3zbq2izg6xhLWNNaERUBpmvA2", "0UUbG2D28kctMrAPKdMPWgkU0eZ2:mk5J2QgBj2gCvcVOhJMMQbRBl9E2", "0UUbG2D28kctMrAPKdMPWgkU0eZ2:mk5J2QgBj2gCvcVOhJMMQbRBl9E2"],
  creationTime: "2023-01-21T16:21:16.924Z",
  fcmToken: "dVtbmEDHXEUxstHArFuSM5:APA91bExuiC_YPpqiEvxF4GMNTaPYPrccgu2lqM_l2YPA_CTQmeobP2WoWP9jDQZ93N6ukeYqhM0qF3SG5tNpVXg3_VMqu8W8-BQWUJQRPjzoinGZLFyq…",
  firstName: "Ryan",
  friends: { 
    "2Ru3zbq2izg6xhLWNNaERUBpmvA2": { 
      _id: "2Ru3zbq2izg6xhLWNNaERUBpmvA2",
      firstName: "Milad",
      lastName: "Mesbahi",
      profilePicture: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F2Ru3zbq2izg6xhLWNNaERUBpmvA2%2FprofilePicture0.jpeg?alt=media&token=1ca6e7a0-d88d-444e-8bc7-47e7a9aa3c86",
      type: "friend",
    },
    "mk5J2QgBj2gCvcVOhJMMQbRBl9E2": {
      _id: "mk5J2QgBj2gCvcVOhJMMQbRBl9E2",
      firstName: "Kara",
      lastName: "Swisher",
      profilePicture: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2Fmk5J2QgBj2gCvcVOhJMMQbRBl9E2%2FprofilePicture0.jpeg?alt=media&token=8c2f61f1-89d4-493f-9ce6-75d5c612594b",
      type: "friend",
    }
  },
  gender: "Male",
  genderInterest: "Women",
  gradYear: 2024,
  groups: [{_id: "875574f8-cf22-4ae6-bf5d-88747fb9ef31", name: "Sigma Alpha Epsilon", profilePicture: ""}],
  hometown: "New York, NY",
  interests: ["Golf", "Swimming", "Beach", "Investing", "Food", "Crypto", "Football"],
  lastName: "Murphy",
  lastOn: 1680207014222,
  lastUpdateProfilePicture: {_id: 3, url: "https://firebasestorage.googleapis.com:443/v0/b/sl…=media&token=672424a6-4a45-444b-acaa-d3f6164724a5", time: 1678987144678},
  lastUpdatePrompts: {_id: 2, prompt: "My favorite spots in LA", message: "Santa Monica — hitting the beach", time: 1675183110596},
  major: "Computer Science",
  memberDatabase: "UCLA",
  objectID: "0UUbG2D28kctMrAPKdMPWgkU0eZ2",
  openTo: ["New friends", "Relationship (causal)", "Relationship (serious)"],
  phone: "+16505550001",
  profilePicture0: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture0.jpeg?alt=media&token=331dc561-78f1-46e5-9e1a-2030b37e0dac",
  profilePicture1: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture1.jpeg?alt=media&token=f6d5bc11-6c7a-47ea-9dd3-49e837d3ac79",
  profilePicture2: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture2.jpeg?alt=media&token=e6c6bbc4-d019-460e-989b-e29fbe51ef02",
  profilePicture3: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture3.jpeg?alt=media&token=672424a6-4a45-444b-acaa-d3f6164724a5",
  profilePicture4: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture4.jpeg?alt=media&token=60132444-29c1-4a86-9fcf-26df3dea4578",
  profilePicture5: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture5.jpeg?alt=media&token=d36b3142-2c01-41ec-9823-9cf6314967d5",
  profilePicture6: "https://firebasestorage.googleapis.com:443/v0/b/slate-36f42.appspot.com/o/users%2F0UUbG2D28kctMrAPKdMPWgkU0eZ2%2FprofilePicture6.jpeg?alt=media&token=04567691-fe3d-4dbc-8a6f-50785cd3bae1",
  profileViews: 548,
  profileViewsPrevTotal: 548,
  prompts: {0: {prompt: "Talk to me about", message: "Anything you're interested in!", time: 1674484061881}, 1: {prompt: "What I'm up to these days", message: "Trying to get through these finals...", time: 1674482287125}, 2: {prompt: "My favorite spots in LA", message: "Santa Monica — hitting the beach", time: 1675183110595}},
  relationshipStatus: "Single",
  score: 5,
  specs: ["Greek life", "Out of state", "Scholarship", "Athlete"],
  spotlightMembers: ["qkArlmziafW3Qk4rp4ZTi0KgJJh2", "6mq4zbTpbASpFJmzaRrD2kfolHm1", "vdJCl8ogGGazL0KrvMdzZ3fbSOE2", "4jvI8aP02lb6JWKvK6wzUknSOPJ3", "djXkosd3RIbvM3kCZTShPRY6Xq22"],
  
  }
